import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import NvidiaLogo from "jsx:../../../images/nvidia.svg";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";
import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import {
    BARE_METAL_MAP,
    METAL_MAP,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_GPU,
    VPS_MAP,
} from "../constants";
import { HardwareCardHeader } from "./header/header";
import { HardwareCardHighlights } from "./highlights";
import { HardwareCardNotice } from "./notice";

/**
 * HardwareCard component renders a card with hardware details.
 *
 * @param {Object} props - The properties object.
 * @param {string|number} props.fullCost - The full cost of the hardware.
 * @param {string|number} props.discountedCost - The discounted cost of the hardware.
 * @param {string} props.billingCycle - The billing cycle for the hardware.
 * @param {boolean} props.isSelected - Whether the hardware is selected.
 * @param {boolean} props.isBareMetal - Whether the hardware is bare metal.
 * @param {boolean} props.hasTerms - Whether the hardware has terms.
 * @param {boolean} props.disabled - Whether the hardware is disabled.
 * @param {function} props.onClick - The click handler for the hardware card.
 * @param {string} props.serverType - The type of server.
 * @param {Object} props.data - The data object containing hardware details.
 * @returns {JSX.Element} The rendered HardwareCard component.
 */
export function HardwareCard({
  fullCost = '',
  discountedCost = '',
  billingCycle,
  isSelected,
  isBareMetal = false,
  hasTerms = false,
  noticeContent = '',
  disabled,
  onClick,
  serverType = SERVER_TYPE_CLOUD_VPS,
  data,
}) {
  const {
    disk_type: diskType,
    raid_level: raidLevel,
    link_speed: linkSpeed,
    gpu_name: gpuName,
    cpu_model: cpuModel,
    id,
  } = data;

  const isMetalServerType = serverType === SERVER_TYPE_CLOUD_METAL || isBareMetal;

  /**
   * Converts link speed value to a readable format.
   * 
   * @param {number|string} value - The link speed value.
   * @returns {string} The formatted link speed.
   */
  const getLinkSpeed = (value) => {
    if (!value) {
      return "";
    }
    return removeDecimalIfWhole((Number(value) / 1000).toFixed(1));
  };

  /**
   * Memoized function to get the hardware map based on server type.
   * 
   * @returns {Map} The hardware map.
   */
  const getHardwareMap = useMemo(() => {
    switch (serverType) {
      case SERVER_TYPE_CLOUD_METAL:
        return METAL_MAP;
      case SERVER_TYPE_BARE_METAL:
      case SERVER_TYPE_GPU:
        return BARE_METAL_MAP;
      default:
        return VPS_MAP;
    }
  }, [serverType]);

  /**
   * Memoized function to get the hardware list elements.
   * 
   * @returns {Array} The list of hardware elements.
   */
  const getHardwareList = useMemo(() => {
    let list = [];
    getHardwareMap.forEach((item, index) => {
      list.push(
        <React.Fragment key={index}>
          <dt>{item.label}</dt>
          <dd className="text-right">{`${data[index]}${item.suffix ? ` ${item.suffix}` : ""}`}</dd>
        </React.Fragment>
      )
    });
    
    return list;
  }, [getHardwareMap, data]);

  /**
   * Renders the GPU chip if applicable.
   * 
   * @param {string} gpuName - The name of the GPU.
   * @returns {JSX.Element|null} The rendered GPU chip or null.
   */
  const getGPU = (gpuName) => {
    if (!gpuName || serverType !== SERVER_TYPE_GPU) {
      return null;
    }

    // Trim "Nvidia" from string because the brand is represented with their logo.
    const trimmedGpuName = gpuName.replace("Nvidia", "").trim();

    return (
      <div className="flex mt-4">
        <Chip className="!rounded !p-[6px] flex gap-2 align-center items-center border-none !text-black !bg-lw-disabled">
          <NvidiaLogo className="basis-[74px] shrink-0"/>
          <span className="text-[14px] font-medium">{trimmedGpuName}</span>
        </Chip>
      </div>
    );
  };

  return (
    <CardButtonBase
      disabled={disabled}
      isSelected={isSelected}
      onClick={onClick}
      uniqueId={id}
      header={hasTerms ? (
        <HardwareCardNotice 
          disabled={disabled}
          content={noticeContent}
        /> 
      ) : null}
      content={
        <div className="px-4 py-6 flex flex-col w-full">
          <HardwareCardHeader
            isBareMetal={isMetalServerType}
            title={isMetalServerType && cpuModel ? cpuModel : ''}
            chip={
              <div className={classnames(
                'flex',
                'flex-col',
                'gap-2',
                isMetalServerType ? 'items-end' : 'items-start',
              )}>
                <Chip
                  className={disabled ? 'text-lw-text-disabled !bg-lw-disabled' : ''}
                  disabled={disabled}
                  variant={hasTerms && !disabled ? 'secondary': 'primary'}
                >
                  {hasTerms ? `$${discountedCost} ${billingCycle}` : `$${fullCost} ${billingCycle}`}
                </Chip>
                {hasTerms ? (
                  <del className="text-xs text-lw-text-disabled">{`$${fullCost} ${billingCycle}`}</del>
                ) : null}
              </div>
            }
            highlights={
              isMetalServerType ? (
                <HardwareCardHighlights
                  diskType={diskType ? ` ${diskType}` : ""}
                  raidLevel={raidLevel ? ` RAID-${raidLevel}` : ""}
                  linkSpeed={getLinkSpeed(linkSpeed)}
                  isBareMetal={isBareMetal}
                  disabled={disabled}
                />
              ) : null
            }
          />
          <dl className="grid grid-cols-2 text-lw-text-disabled text-sm">
            {getHardwareList}
          </dl>
          {getGPU(gpuName)}
        </div>
      }
    />
  );
}

HardwareCard.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discountedCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  billingCycle: PropTypes.string,
  isSelected: PropTypes.bool,
  isBareMetal: PropTypes.bool,
  hasTerms: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  serverType: PropTypes.oneOf([
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_GPU,
  ]),
  data: PropTypes.object.isRequired,
};
