import BareMetalLogo from "jsx:../../../images/bare-metal.inline.svg";
import CloudMetalLogo from "jsx:../../../images/cloud-metal.inline.svg";
import CloudVpsLogo from "jsx:../../../images/cloud-vps.inline.svg";
import GPULogo from "jsx:../../../images/gpu.inline.svg";

/**
 * Constants that define the different product codes available.
 * These constants are used to identify and differentiate between various cloud and bare metal products.
 */
export const PRODUCT_VPS_LIN = "PKG-Cloud.VPS";
export const PRODUCT_VPS_WIN = "PKG-Cloud.VPS.WIN";
export const PRODUCT_METAL_LIN = "PKG-Cloud.Metal";
export const PRODUCT_METAL_WIN = "PKG-Cloud.Metal.WIN";
export const PRODUCT_BARE_METAL = "PKG-Bare.Metal";
export const PRODUCT_GPU = "PKG-Bare.Metal.GPU";


export const TYPE_CLOUD_VPS_LIN = "Cloud.VPS.Linux";
export const TYPE_CLOUD_VPS_WIN = "Cloud.VPS.Windows";
export const TYPE_CLOUD_METAL_LIN = "Cloud.Metal.Linux";
export const TYPE_CLOUD_METAL_WIN = "Cloud.Metal.Windows";
export const TYPE_BARE_METAL_LIN = "Bare.Metal.Linux";
export const TYPE_BARE_METAL_GPU_LIN = "Bare.Metal.GPU.Linux";

/**
 * An array that lists all the available cloud products.
 * Each element in this array is a product code representing a specific cloud or bare metal product.
 */
export const CLOUD_PRODUCTS = [
  PRODUCT_VPS_LIN,
  PRODUCT_VPS_WIN,
  PRODUCT_METAL_LIN,
  PRODUCT_METAL_WIN,
  PRODUCT_BARE_METAL,
  PRODUCT_GPU,
];

export const CLOUD_TYPES = [
  TYPE_CLOUD_VPS_LIN,
  TYPE_CLOUD_VPS_WIN,
  TYPE_CLOUD_METAL_LIN,
  TYPE_CLOUD_METAL_WIN,
  TYPE_BARE_METAL_LIN,
  TYPE_BARE_METAL_GPU_LIN,
];

/**
 * Constants that define the different server types available.
 * These constants are used to identify and differentiate between various server types.
 */
export const SERVER_TYPE_CLOUD_VPS = "cloud-vps";
export const SERVER_TYPE_CLOUD_METAL = "bare-metal-cloud";
export const SERVER_TYPE_BARE_METAL = "bare-metal-server";
export const SERVER_TYPE_GPU = "gpu-accelerated";

export const COLLECTION_DEFAULT_PACKAGES = [
  {
    "type": TYPE_CLOUD_VPS_LIN,
    "code": PRODUCT_VPS_LIN,
  },
  {
    "type": TYPE_CLOUD_VPS_WIN,
    "code": PRODUCT_VPS_WIN,
  },
  {
    "type": TYPE_CLOUD_METAL_LIN,
    "code": PRODUCT_METAL_LIN
  },
  {
    "type": TYPE_CLOUD_METAL_WIN,
    "code": PRODUCT_METAL_WIN
  },
  {
    "type": TYPE_BARE_METAL_LIN,
    "code": PRODUCT_BARE_METAL
  },
  {
    "type": TYPE_BARE_METAL_GPU_LIN,
    "code": PRODUCT_GPU
  }
];

export const COLLECTION_DEFAULT = {
  id: '',
  description: '',
  packages: COLLECTION_DEFAULT_PACKAGES,
};

/**
 * An object that maps server types to their respective product details.
 * Each key in this object corresponds to a specific server type, and the value
 * is an object containing detailed information about the product.
 *
 * The product details include:
 * - id: A unique identifier for the product type.
 * - productCode: The product code that corresponds to the server type.
 * - name: The display name of the product.
 * - description: A brief description of the product, highlighting its features and benefits.
 * - cost: The starting cost of the product, typically presented as a monthly fee.
 * - logo: A JSX element that renders the logo for the product.
 * - path: The URL path where more information about the product can be found.
 *
 * Example usage:
 * PRODUCT_TYPES[SERVER_TYPE_CLOUD_VPS].name; // returns "Cloud VPS"
 */
export const PRODUCT_TYPES = {
  [SERVER_TYPE_CLOUD_VPS]: {
    id: "vps",
    productCode: PRODUCT_VPS_LIN,
    name: "Cloud VPS",
    description: "Single-tenant cloud environments offer the perfect blend of control and affordability.",
    cost: "From $5 Monthly",
    logo: <CloudVpsLogo />,
    path: `/${SERVER_TYPE_CLOUD_VPS}/`,
  },
  [SERVER_TYPE_CLOUD_METAL]: {
    id: "cloud-metal",
    productCode: PRODUCT_METAL_LIN,
    name: "Bare Metal Cloud",
    description: "High-performance dedicated single-tenant servers plus advanced virtualization equals fully customizable hosting.",
    cost: "From $89 Monthly",
    logo: <CloudMetalLogo />,
    path: `/${SERVER_TYPE_CLOUD_METAL}/`,
  },
  [SERVER_TYPE_BARE_METAL]: {
    id: "bare-metal",
    productCode: PRODUCT_BARE_METAL,
    name: "Bare Metal Server",
    description: "Our metal solutions deliver peak performance, precise control, dedicated resources, and high reliability.",
    cost: "From $79 Monthly",
    logo: <BareMetalLogo />,
    path: `/${SERVER_TYPE_BARE_METAL}/`,
  },
  [SERVER_TYPE_GPU]: {
    id: "gpu",
    productCode: PRODUCT_GPU,
    name: "GPU Accelerated",
    description: "High-performance servers with dedicated GPUs for accelerated workloads like AI, machine learning, and graphics rendering.",
    cost: "From $880 Monthly",
    logo: <GPULogo />,
    path: `/${SERVER_TYPE_GPU}/`,
  },
};

/**
 * A map that defines the subscription cycles with both short and long display names.
 * Each key in this map corresponds to a cycle identifier, and the value is an object
 * containing the short and long display names for that cycle.
 *
 * Example usage:
 * CYCLE_MAP.get("yearly"); // returns { short: "1 Year", long: "Yearly" }
 */
export const CYCLE_MAP = new Map([
  ["monthly", { short:  "Monthly", long: "Monthly" }],
  ["biannually", { short: "6 Months", long: "Every 6 Months" }],
  ["yearly", { short: "1 Year", long: "Yearly" }],
  ["biennially", { short: "2 Years", long: "Every 2 Years" }],
  ["triennially", { short: "3 Years", long: "Every 3 Years" }],
]);

/**
 * An array that defines the tabs for selecting operating systems and applications.
 * Each element in this array is an object representing a tab, with the following properties:
 * - id: A unique identifier for the tab.
 * - name: The display name of the tab.
 */
export const OS_TABS = [
  {
    id: 'os',
    name: 'Operating Systems',
  },
  {
    id: 'app',
    name: 'Applications',
  }
];

// Configuration Constants
export const ROOT_MARGIN = "-25% 0% -75% 0%";
export const SCROLL_OFFSET = 180;

export const CONFIGURATION_SECTIONS = [
  { id: "server-type", label: "Server Type" },
  { id: "location", label: "Location" },
  { id: "os", label: "Operating System" },
  { id: "management", label: "Management" },
  { id: "image", label: "Image" },
  { id: "subscription", label: "Subscription" },
  { id: "hardware", label: "Hardware" },
  { id: "add-ons", label: "Add-Ons" },
];

export const CONFIGURATION_SECTIONS_BARE_METAL = [
  { id: "server-type", label: "Server Type" },
  { id: "location", label: "Location" },
  { id: "image", label: "Image" },
  { id: "subscription", label: "Subscription" },
  { id: "hardware", label: "Hardware" },
  { id: "add-ons", label: "Add-Ons" },
];

/**
 * A map that defines the hardware configurations available for Cloud VPS configuration cards.
 */
export const VPS_MAP = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

/**
 * A map that defines the hardware configurations available for Bare Metal Cloud configuration cards.
 */
export const BARE_METAL_MAP = new Map([
  ["cores", { label: "Cores / Threads", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
]);

/**
 * A map that defines the hardware configurations available for Bare Metal and GPU configuration cards.
 */
export const METAL_MAP = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

/** 
 * A map that defines the summary hardware configuration for Cloud VPS. 
 */
export const SUMMARY_HARDWARE_VPS = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

/** 
 * A map that defines the summary hardware configuration for Bare Metal Cloud
 * servers. 
 */
export const SUMMARY_HARDWARE_METAL = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

/** 
 * A map that defines the summary hardware configuration for Bare Metal and
 * GPU servers.
 */
export const SUMMARY_HARDWARE_BARE_METAL = new Map([
  ["cores", { label: "Cores / Threads", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

/**
 * A map that associates server types with their respective summary hardware configurations.
 * Each key in this map corresponds to a specific server type, and the value is a map
 * containing the hardware details for that server type.
 *
 * The summary hardware configurations include details such as cores, memory, disk, and bandwidth.
 *
 * Example usage:
 * SUMMARY_ROUTE_MAP.get(SERVER_TYPE_CLOUD_VPS); // returns SUMMARY_HARDWARE_VPS map
 */
export const SUMMARY_ROUTE_MAP = new Map([
  [SERVER_TYPE_CLOUD_VPS, SUMMARY_HARDWARE_VPS],
  [SERVER_TYPE_CLOUD_METAL, SUMMARY_HARDWARE_METAL],
  [SERVER_TYPE_BARE_METAL, SUMMARY_HARDWARE_BARE_METAL],
  [SERVER_TYPE_GPU, SUMMARY_HARDWARE_BARE_METAL],
]);

/**
 * An array that lists the available add-ons for the summary hardware configurations.
 * Each element in this array represents a specific add-on that can be included in the
 * summary of a hardware configuration.
 */
export const SUMMARY_ADDONS = [
  "backups",
  "blockStorage",
  "ipAddresses",
  "ipv6",
  "ddos",
];

/**
 * An array that lists the available add-ons specifically for bare metal configurations.
 * Each element in this array represents a specific add-on that can be included in the
 * summary of a bare metal hardware configuration.
 */
export const SUMMARY_ADDONS_BARE_METAL = ["ddos"];

/**
 * An array that lists the available add-ons specifically for Windows configurations.
 * Each element in this array represents a specific add-on that can be included in the
 * summary of a Windows hardware configuration.
 */
export const SUMMARY_ADDONS_WIN = ["antiVirus", "mssql"];

/**
 * A map that associates location codes with their respective geographical details.
 * Each key in this map corresponds to a specific location code, and the value is an
 * object containing details about the location.
 *
 * The location details include:
 * - city: The name of the city.
 * - region: The region where the city is located.
 * - country: The name of the country.
 * - countryCode: The country code.
 *
 * Example usage:
 * LOCATION_MAP.get("lan"); // returns { city: "Michigan", region: "US-Central", country: "United States", countryCode: "usa" }
 */
export const LOCATION_MAP = new Map([
  ["lan", { city: "Michigan", region: "US-Central", country: "United States", countryCode: "usa" }],
  ["phx", { city: "Arizona", region: "US-West", country: "United States", countryCode: "usa" }],
  ["ash", { city: "Virginia", region: "US-East", country: "United States", countryCode: "usa" }],
  ["sjc", { city: "California", region: "US-West", country: "United States", countryCode: "usa" }],
  ["lon", { city: "London", region: "EU-West", country: "United Kingdom", countryCode: "uk" }],
  ["syd", { city: "Sydney", region: "AP-Southeast", country: "Australia", countryCode: "aus" }],
  ["ams", { city: "Amsterdam", region: "EU-West", country: "Netherlands", countryCode: "nl" }],
]);

// Hardware Category Order
export const HARDWARE_CATEGORY_ORDER = ["gp", "mem-opt", "cpu-opt"];

// Error Messages
export const ERROR_MANAGEMENT_LEVEL = "Please choose another location to see more options.";
export const ERROR_API_FETCH = "There was a problem loading our product data. Please refresh and try again";
export const ERROR_API_POST = "There was a problem adding deploying your configuration. Please contact our support.";

/* An array that defines the comparison of management levels across different features.
 * Each sub-array represents a feature and its availability across three different management levels.
 * The values in the sub-arrays are:
 * - 1: The feature is available in the management level.
 * - 0: The feature is not available in the management level.
 *
 * The order of the management levels in the sub-arrays is consistent across all features.
 */
export const COMPARE_MANAGEMENT_LEVELS = [
  ["24/7/365", 1, 1, 1],
  ["Fully Managed Network Infrastructure", 1, 1, 1],
  ["Fully Managed Hardware", 1, 1, 1],
  ["Wholly owned Liquid Web Data Centers", 1, 1, 1],
  ["Level 3 Technicians On-site 24/7/365", 1, 1, 1],
  ["System Level Health Monitoring and Graphing", 1, 1, 1],
  ["System Level Monitoring Alerts & Notifications", 1, 1, 1],
  ["100% Uptime SLA on These Items", 1, 1, 1],
  ["Dedicated Account Executive Team", 1, 1, 1],
  ["Installation and Full Support of Core Software Package", 0, 1, 1],
  ["Core Operating System Updates & Patches", 0, 1, 1],
  ["Security Enhancements", 0, 1, 1],
  ["Full Web Server Support including Apache (Linux)", 0, 1, 1],
  ["Proactive Response & Restoration of Monitoring Events", 0, 1, 1],
  ["Virus and Spam Protection", 0, 0, 1],
  ["Free External Migrations**", 0, 0, 1],
  ["Full Control Panel Support", 0, 0, 1],
  ["Control Panel Updates and Patches", 0, 0, 1],
];

/**
 * A map that provides descriptions for different hardware categories.
 * Each key in this map corresponds to a hardware category identifier, and the value
 * is a string describing the characteristics and typical use cases for that category.
 *
 * The hardware categories include:
 * - "gp": General purpose virtual machines with balanced resources.
 * - "mem-opt": Memory-optimized virtual machines with larger amounts of memory.
 * - "cpu-opt": CPU-optimized virtual machines with more processing cores.
 *
 * Example usage:
 * HARDWARE_CATEGORY_DESCRIPTIONS.get("gp"); // returns "Virtual machines with balanced resources suitable for a variety of workloads. Useful for website hosting, development/testing, and general application hosting."
 */
export const HARDWARE_CATEGORY_DESCRIPTIONS = new Map([
  ["gp", "Virtual machines with balanced resources suitable for a variety of workloads. Useful for website hosting, development/testing, and general application hosting."],
  ["mem-opt", "Virtual machines focused on providing larger amounts of memory. Useful for databases, in-memory caches, and other memory bound workloads."],
  ["cpu-opt", "Virtual machines focused on providing more processing cores. Useful for CI/CD, video processing, and other cpu bound workloads."],
]);

// Default Subscription
export const DEFAULT_SUBSCRIPTION = {
  cycle: "monthly",
  discount: 0,
  months: 1,
};

// Default Discount
export const DEFAULT_DISCOUNT = {
  type: "cycle",
  discount: 0,
  repetitions: null,
  months: null,
};
