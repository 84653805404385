import classnames from "classnames";

export const HardwareCardNotice = ({
  className,
  content,
  disabled = false,
}) => {
  if (!content) {
    return null;
  }

  return (
    <div
      className={classnames(
        "bg-lw-ui-purple-light",
        "text-lw-ui-purple-dark",
        "text-sm",
        "text-center",
        "p-2",
        "w-full",
        "rounded-t-[.5rem]",
        "border-b",
        "border-lw-ui-border",
        {
          '!bg-lw-disabled': disabled,
          '!text-lw-text-disabled': disabled,
        },
        className
      )}
    >
      {content}
    </div>
  );
};
