import { forwardRef } from "react";

import { useAppState } from "../../../../state";
import { useSubscriptionPricing } from "../../../../hooks/useSubscriptionPricing";
import { removeDecimalIfWhole } from "../../../../utils/removeDecimalIfWhole";
import { SkeletonCard } from "../../common/skeleton-card";
import { CYCLE_MAP } from "../../constants";

export const TermSection = forwardRef(function (props, ref) {
  const [{ configuration: configState }] = useAppState();
  const { repetitions } = configState.discount || null;
  const { discountedCost, discount, cycle } = useSubscriptionPricing();
  const { onClick } = props;

  let discountText = "";
  if (Number(discount) > 0) {
    discountText = `Save ${discount}%${repetitions ? ` for ${repetitions} months` : " per month"}`;
  }

  if (!configState.hasSubscriptionCycle) {
    return null;
  }

  return (
    <>
      <table className="text-sm w-full" aria-label="Operating System Choices">
        <caption>
          <div className="flex justify-between w-full text-xs mb-2">
            <span className="uppercase text-lw-text-disabled tracking-wide">
              Term
            </span>
            <button
              aria-label="Edit your Term choice"
              className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
              onClick={() => onClick(ref)}
            >
              Edit
            </button>
          </div>
        </caption>
        <thead className="sr-only">
          <tr>
            <th>Term</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {configState.isLoading || configState.isError === "api-fetch" ? (
            <tr>
              <td>
                <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
              </td>
            </tr>
          ) : (
            <>
              <tr className="align-top">
                <td>
                  <span>{`Billed ${CYCLE_MAP.get(cycle).long}`}</span>
                  <span className="text-lw-text-disabled">{` ($${removeDecimalIfWhole(discountedCost)})`}</span>
                </td>
                <td className="text-right">
                  { }
                  <span className="font-bold text-lw-ui-green">
                    {discountText}
                  </span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <hr className="border-t border-lw-ui-border my-4 " />
    </>
  );
});
