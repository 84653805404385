import PropTypes from "prop-types";
import classnames from "classnames";

export const HardwareCardHeader = ({
  isBareMetal = false,
  chip,
  title,
  highlights,
}) => {
  const classes = classnames(
    "flex",
    "w-full",
    "mb-2",
    "gap-2",
    { "items-center": !isBareMetal },
    isBareMetal && [
      "items-start",
      "justify-between",
      "lg:flex-col-reverse",
      "lg:items-start",
      "xl:flex-row",
    ],
  );

  const headerClasses = classnames(
    "flex",
    "justify-between",
    "gap-2",
    isBareMetal && ["mb-4"],
    !isBareMetal && ["mb-3"],
  );

  return (
    <div className={headerClasses}>
      {isBareMetal && title && (
        <div>
          <span className="inline-block mt-1 text-lg leading-5">
            {title}
          </span>
          {highlights}
        </div>
      )}
      {chip && (
        <div className="flex-none">
          {chip}
        </div>
      )}
    </div>
  );
};

HardwareCardHeader.propTypes = {
  isBareMetal: PropTypes.bool,
  title: PropTypes.string,
  chip: PropTypes.element,
};
