import axios from "axios";
import { useMutation } from "@tanstack/react-query";

export function useAddToCart() {
  async function createCart(cartData) {
    const cartEndpoint = `${process.env.CART_API_URL}/helpers/configurator/add`;
    const config = {
      auth: {
        username: process.env.LW_API_GUEST_USER,
        password: process.env.LW_API_GUEST_PWD,
      },
    };

    return axios
      .post(cartEndpoint, { params: cartData }, config)
      .then((res) => res.data);
  }

  const addToCart = useMutation({
    mutationFn: createCart,
  });

  return {
    addToCart,
  };
}
