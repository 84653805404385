import { useMemo } from "react";
import { useAppState } from "../state"

export function useSubscriptionPricing() {
  const [{ configuration: configState, summary: summaryState }] = useAppState();
  const {
    serverQty,
    subscriptionCycle: { months, cycle },
    discount: { discount: configDiscount, repetitions },
  } = configState;

  // Create cost sum from summary
  const configSum = useMemo(() => {
    // Exclude hardwareDetails from calculation
    const { hardwareDetails, ...rest } = summaryState;
    let sum = 0;

    Object.keys(rest).forEach((key) => {
      sum += Number(summaryState[key].cost);
    });

    return sum;
  }, [summaryState]);

  const multiplier = repetitions && (repetitions < months) ? repetitions / months : 1;
  const adjustedDiscount = (configDiscount / 100) * multiplier;

  // Full cost
  const fullCost = Number(configSum) * months * serverQty;
  // Full cost with discount
  const discountedCost = fullCost - fullCost * parseFloat(adjustedDiscount);
  // Single unit cost with discount
  const singleCost = Number(configSum) - Number(configSum) * parseFloat(adjustedDiscount);

  return {
    fullCost,
    singleCost,
    discountedCost,
    discount: configDiscount,
    cycle,
  }
}
