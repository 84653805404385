import classnames from "classnames";

import DiskIcon from "jsx:../../../../images/icon-disk.inline.svg";
import LinkSpeedIcon from "jsx:../../../../images/icon-link-speed.inline.svg";
import RaidIcon from "jsx:../../../../images/icon-raid.inline.svg";

export function HardwareCardHighlights({
  diskType,
  raidLevel,
  linkSpeed,
  isBareMetal,
  disabled,
}) {
  const SSD = () => (
    <>
      <DiskIcon />
      {diskType}
    </>
  );

  const RAID = () => (
    <>
      <RaidIcon />
      {raidLevel}
    </>
  );

  const LinkSpeed = () => (
    <>
      <LinkSpeedIcon />
      {`${linkSpeed} Gbps`}
    </>
  );

  return (
    <div className="flex justify-between items-center gap-2 mt-[9px] relative">
      <div className={classnames(
        "flex",
        "flex-wrap",
        "items-center",
        "gap-2",
        "text-xs",
        disabled ? "text-lw-text-disabled" : "text-black",
      )}>
        <span className="flex gap-1 items-center">
          <SSD />
        </span>
        {raidLevel && (
          <span className="flex gap-1 items-center">
            <RAID />
          </span>
        )}
        {isBareMetal && linkSpeed && (
          <span className="flex gap-1 items-center">
            <LinkSpeed />
          </span>
        )}
      </div>
    </div>
  );
}

