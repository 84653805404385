import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useCollectionCloudflareKV } from "../hooks/useCollectionCloudflareKV";
import { useAppState } from "../state";
import actions from "../state/configuration/actions";

import { COLLECTION_DEFAULT } from "../components/configuration/constants";

const ProductCollectionContext = createContext();

export const useProductCollection = () => {
  return useContext(ProductCollectionContext);
};

export const ProductCollectionProvider = ({ children }) => {
  const collectionData = {
    ...COLLECTION_DEFAULT,
    isLoading: true,
    isError: false,
  };
  const [, dispatch] = useAppState();
  const [data, setData] = useState(collectionData);
  const [searchParams] = useSearchParams();
  const collectionId = searchParams.get("collection");
  const {
    isLoading,
    isError,
    data: kvData,
  } = useCollectionCloudflareKV(collectionId);

  useEffect(() => {
    const shouldUpdateData = !isLoading && !isError && kvData;

    // If no collection ID is found, use initial state
    if (collectionId === null) {
      setData((previous) => ({
        ...previous,
        isLoading: false,
        isError: false,
      }));

      return;
    }

    if (shouldUpdateData) {
      const { id, description, packages} = kvData || {};

      // If the data is incomplete, use initial state
      if (!Array.isArray(packages) || packages.length < 6) {
        setData((previous) => ({
          ...previous,
          isLoading: false,
          isError: false,
        }));

        return;
      }

      dispatch(actions.setCollectionId(id));
      dispatch(actions.setCollectionDescription(description));

      setData({
        packages,
        description,
        isLoading: false,
        isError: false,
      });
    }
  }, [kvData, isLoading, isError]);

  return (
    <ProductCollectionContext.Provider value={data}>
      {children}
    </ProductCollectionContext.Provider>
  );
};
