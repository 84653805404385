import PropTypes from "prop-types";
import classnames from "classnames";
import { CardButtonBase } from "../common/card/CardButtonBase";

export function SubscriptionCard({ cycle, isSelected, onClick, title, chip }) {
  return (
    <CardButtonBase
      key={cycle.cycle}
      className="lg:!p-4 2xl:!p-4"
      isSelected={isSelected}
      onClick={onClick}
    >
      <div className={classnames(
        '@container',
        'flex',
        'w-full',
        'h-full',
        chip ? 'flex-col' : 'flex-row',
      )}>
        <div className={classnames(
          'flex',
          'flex-wrap',
          'gap-2',
          'items-start',
          'flex-col',
          '@[190px]:flex-row',
          !chip && 'w-full',
          chip && 'justify-between',
          chip && '@[190]:items-center',
        )}>
          <h4 className="text-lg font-normal">{title}</h4>
          {chip}
        </div>
      </div>
    </CardButtonBase>
  );
}

SubscriptionCard.propTypes = {
  cycle: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  chip: PropTypes.node,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
