import classnames from "classnames";
import Tippy from '@tippyjs/react';

import DiskIcon from "jsx:../../../images/icon-disk.inline.svg";
import InfoIcon from "jsx:../../../images/icon-info.inline.svg";
import LinkSpeedIcon from "jsx:../../../images/icon-link-speed.inline.svg";
import RaidIcon from "jsx:../../../images/icon-raid.inline.svg";

export function HardwareToolTip() {
  return (
    <Tippy
      interactiveBorder={10}
      maxWidth={300}
      offset={[-6, 4]}
      placement="right-end"
      content={
        <div
          className={classnames(
            "flex",
            "flex-col",
            "gap-1",
            "rounded",
            "bg-white",
            "p-2",
            "text-xs",
            "shadow-toolTip"
          )}
        >
          <div className="flex gap-8 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <DiskIcon />
              SSD
            </span>
            <span>Solid State Drive storage</span>
          </div>
          <div className="flex gap-8 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <DiskIcon />
              NVMe
            </span>
            <span>NVMe Solid State Drive storage</span>
          </div>
          <div className="flex gap-8 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <DiskIcon />
              HDD
            </span>
            <span>Hard Disk Drive storage</span>
          </div>
          <div className="flex gap-1 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <RaidIcon />
              RAID-1
            </span>
            <span>Mirrored storage drives</span>
          </div>
          <div className="flex gap-8 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <RaidIcon />
              RAID-10
            </span>
            <span>Mirrored and striped drives</span>
          </div>
          <div className="flex gap-1 items-center justify-between">
            <span className="flex gap-1 items-center text-lw-text-disabled">
              <LinkSpeedIcon />
              Gbps
            </span>
            <span>Network speed</span>
          </div>
        </div>
      }
    >
      <button className="transition-colors duration-75 text-lw-text-disabled hover:text-black">
        <InfoIcon />
      </button>
    </Tippy>
  );
}
