import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";

import { useProductCollection } from '../context/ProductCollectionContext';

import { CLOUD_TYPES } from "../components/configuration/constants";

export function useProductDetails() {
  const collectionData = useProductCollection();
  const [collection, setCollection] = useState([]);


  const collectionProducts = useMemo(() => {
    if (!collectionData.isLoading && !collectionData.isError && collectionData?.packages) {
      return collectionData.packages;
    }

    return [];
  }, [collectionData]);

  useEffect(() => {
    setCollection(collectionProducts);
  }, [collectionProducts]);


  async function fetchProductDetailsByCode(productCode) {
    const detailsEndpoint = `${process.env.CART_API_URL}/helpers/configurator/details`;
    const config = {
      headers: {
        "X-FORCE-COMPLIANT-HTTP-STATUS-CODES": 1,
      },
      auth: {
        username: process.env.LW_API_GUEST_USER,
        password: process.env.LW_API_GUEST_PWD,
      },
    };
    const { data } = await axios.post(
      detailsEndpoint,
      {
        params: {
          package_code: productCode,
        },
      },
      config,
    );

    return data;
  }

  const queries = useQueries({
    queries: collection.map((product) => {
      return {
        queryKey: ["productDetails", product.code],
        queryFn: async () => await fetchProductDetailsByCode(product.code),
      };
    }),
  });

  return queries.reduce((acc, details, idx) => {
    const packageType = CLOUD_TYPES[idx];
    acc[packageType] = details;

    return acc;
  }, {});
}
