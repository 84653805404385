import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const COLLECTION_API_URL = process.env.COLLECTION_API_URL;

export function useCollectionCloudflareKV(id) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);

  const getCollectionData = useCallback(async () => {
    if (!id || !COLLECTION_API_URL) {
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.get(`${COLLECTION_API_URL}${id}`);
      setData(data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      console.error(errorMessage);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getCollectionData();
  }, [getCollectionData]);

  return { data, isLoading, isError };
}
