import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export function Chip({
  disabled = false,
  variant = "primary",
  className = "",
  children
}) {
  const classes = classnames(
    "inline-block",
    "rounded",
    "border",
    "text-[13px]",
    "leading-none",
    "p-[6px]",
    variant === "primary" && [
      "border-lw-ui-border",
      "bg-lw-disabled",
      "text-black",
    ],
    variant === "secondary" && [
      "border-lw-ui-purple-dark",
      "bg-lw-ui-purple-light",
      "text-lw-ui-purple-dark",
    ],
    disabled && [
      "!bg-lw-disabled",
      "!text-lw-text-disabled",
    ],
    className,
  );

  return <span className={classes}>{children}</span>;
}

Chip.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
