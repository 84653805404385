import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const CardButtonBase = forwardRef(function CardButtonBase(
  {
    uniqueId,
    className,
    isSelected,
    disabled,
    onClick,
    header,
    content,
    children,
  },
  ref,
) {
  const [effect, setEffect] = useState(false);
  const cardClasses = classnames(
    "flex",
    "transition-all",
    "text-left",
    "border",
    "border-lw-ui-border",
    "rounded-xl",
    "bg-lw-off-white",
    "disabled:bg-lw-disabled",
    "disabled:border-lw-disabled",
    "disabled:text-lw-text-disabled",
    isSelected && [
      "border-lw-disabled",
      "hover:border-lw-disabled",
      "outline",
      "outline-1",
      "outline-offset-0",
      "outline-lw-ui-border-active",
      "shadow-metal",
      "bg-white",
    ],
    !isSelected && [
      "focus:outline",
      "focus-within:outline-1",
      "focus-within:outline-offset-0",
      "focus-within:outline-lw-ui-green",
      "hover:outline",
      "hover:outline-1",
      "hover:outline-offset-0",
      "hover:outline-lw-black",
      "hover:bg-white",
    ],
    disabled && [
      "bg-lw-disabled",
      "border-lw-disabled",
      "!outline-none",
      "!outline-transparent",
      "!shadow-none",
      "!outline-none",
      "hover:!outline-transparent",
      "hover:!shadow-none",
      "text-lw-text-disabled",
    ],
    effect && ["animate-shadow-pulse"],
    !content && !header && ["py-3", "px-4", "lg:p-4"],
    header && ["flex-col"],
    className,
  );

  return (
    <button
      ref={ref}
      className={cardClasses}
      disabled={disabled}
      onClick={() => {
        setEffect(true);
        onClick();
      }}
      data-id={uniqueId ? uniqueId : null}
      onAnimationEnd={() => setEffect(false)}
    >
      {header}
      {content ? content : children}
    </button>
  );
});

CardButtonBase.propTypes = {
  uniqueId: PropTypes.number,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
