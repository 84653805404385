'use client';

import { useEffect, useState, useMemo } from 'react';

import { useMediaQuery } from 'react-responsive'

const chatConfig = {
  // When the chat button is clicked, the amount of time
  // to wait for the ESW script to report "ready" before
  // assuming something went wrong. Not necessarily a hard
  // failure. The user could just have a slow connection.
  chatStartTimeout: 30000,
  // One of:
  //   dataLayer         Google Tag Manager dataLayer events
  //   googleAnalytics   Send events to Google Analytics directly
  analyticsMode: 'dataLayer',
  // The context for web analytics action names (context:object_action)
  analyticsActionContext: 'lw',
  // Disable if Google Analytics is not implemented.
  useGoogleAnalytics: true,
  // Amount of time to wait for Google Analytics to return
  // a tracker object. The script may block for up to this
  // duration in some situations, such as when an ad blocker
  // has replaced window.ga with a no-op function.
  googleAnalyticsTrackerTimeout: 2500,
  // Determines display details for the pre-chat survey
  preselectDepartment: null,

  // Whether HTML and style rules for proactive chat
  // invitations should be added to the document during
  // initialization.
  injectInvitationUI: false,

  invitationUIHeaderText: 'Need help?',
  invitationUIBodyText: "We're Here To Help You",

  // A cookie to track the last time startChat was called,
  // regardless of success. Used by UI implementations to
  // control things like deferred initialization.
  lastStartTimeCookieName: 'lwChatLastStartTimeDev',

  baseCoreURL: process.env.CHAT_BASE_CORE_URL,
  communityEndpointURL: process.env.CHAT_COMMUNITY_ENDPOINT_URL,
  gslbBaseURL: process.env.CHAT_GSLB_BASE_URL,
  orgId: process.env.CHAT_ORG_ID,
  eswConfigDevName: process.env.CHAT_ESW_CONFIG_DEV_NAME,
  eswScriptURL: process.env.CHAT_ESW_SCRIPT_URL,
  settings: {
    baseLiveAgentContentURL: process.env.CHAT_SETTINGS_BASE_LIVE_AGENT_CONTENT_URL,
    baseLiveAgentURL: process.env.CHAT_SETTINGS_BASE_LIVE_AGENT_URL,
    buttonId: process.env.CHAT_SETTINGS_BUTTON_ID,
    deploymentId: process.env.CHAT_SETTINGS_DEPLOYMENT_ID,
    eswLiveAgentDevName: process.env.CHAT_SETTINGS_ESW_LIVE_AGENT_DEV_NAME,
    isOfflineSupportEnabled: process.env.CHAT_SETTINGS_IS_OFFLINE_SUPPORT_ENABLED,
    chatbotAvatarImgURL: process.env.CHAT_SETTINGS_CHATBOT_AVATAR_IMG_URL,
  },
  storageDomain: process.env.CHAT_STORAGE_DOMAIN,
};

/**
 * render chat component based on the config
 * @returns chat widget
 */

export default function Chat() {
  const [ready, setReady] = useState(false);

  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 2412px)' });

  const bottom = useMemo(() => {
    if (isBigScreen) return null;
    if (isDesktop) return 104;
    return 72; // Default for mobile or tablet
  }, [isMobileOrTablet, isDesktop, isBigScreen]);


  useEffect(() => {
    window.lwSettings = {
      ...(window.lwSettings || {}),
      chat: chatConfig,
    };
    const loadChatWidget = async () => {
      // Dynamically import the chat widget bundle
      await import('./chat-widget.bundled.js');
      setReady(true);
    };

    loadChatWidget();
  }, []);
  if (!ready) return null;

  return <chat-widget bottom={bottom} popups={false}></chat-widget>;
}
