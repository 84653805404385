import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";

import { useAppState } from "../../../state";
import actions from "../../../state/configuration/actions";

import { configurationHelper } from "../../../utils/configurationHelper";

import { useProductDetails } from "../../../hooks/useProductDetails";

import { SelectableCard } from "../common/selectable-card";
import { SkeletonCard } from "../common/skeleton-card";

import { LOCATION_MAP } from "../constants";

const images = {
  usa: new URL("../../../images/flags/usa.png?height=34", import.meta.url),
  nl: new URL("../../../images/flags/nl.png?height=34", import.meta.url),
  uk: new URL("../../../images/flags/uk.png?height=34", import.meta.url),
  aus: new URL("../../../images/flags/aus.png?height=34", import.meta.url),
};

export function SectionServerLocation() {
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { data } = productData[state.productType] || {};
  const { getRegions } = configurationHelper(data);
  const showSkeleton = state.isLoading || state.isError === "api-fetch";

  const locations = useMemo(() => {
    if (!data) {
      return [];
    }

    const regions = getRegions();

    if (regions.length) {
      return orderBy(regions, ["region_id"], ["asc"]);
    }

    return null;
  }, [data, getRegions]);

  function handleLocationSelection(location) {
    dispatch(actions.setServerZoneId(location.zone_id));
    dispatch(actions.setServerRegionId(location.region_id));
    dispatch(actions.setServerLocation(location.name));
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-6">Your Server Location</h3>

      <div className="grid gap-2 lg:gap-4 md:grid-cols-1 xl:grid-cols-2">
        {showSkeleton
          ? Array(6)
            .fill()
            .map((_, key) => (
              <SkeletonCard key={key} className="h-[58px] lg:h-[77px]" />
            ))
          : null}
        {!state.isLoading &&
          state.isError !== "api-fetch" &&
          locations &&
          locations.map((location) => {
            if (!LOCATION_MAP.get(location.name)) {
              return null;
            }

            const { city, region, country, countryCode } = LOCATION_MAP.get(
              location.name,
            );

            return (
              <SelectableCard
                key={location.region_id}
                className="items-center"
                title={<div className="text-lg">{city}</div>}
                subheader={
                  <div className="text-[10px] text-lw-text-disabled">{region}</div>
                }
                value={location.name}
                isSelected={state.serverLocation === location.name}
                startElement={
                  countryCode && images?.[countryCode] ? (
                    <img className="w-[44px]" src={images[countryCode]} alt={`${country} Flag`} />
                  ) : null
                }
                endElement={
                  <div className="text-sm text-lw-text-disabled">{country}</div>
                }
                onClick={() => handleLocationSelection(location)}
              />
            );
          })}
      </div>
    </div>
  );
}
